/**
 * DrvSlider is Shared Component which is used on common page of this Website.
 * Contains slider with dynamic contents.
 *
 * To use this component you need to specify slides(array) with contents, slide(component) which represent
 * one slide on slider, slider class-name, slide class-name, effect which by default is none, center to center one slide by default false
 * pagination by default false, slidePerView number of slides that we want to view and breakpoints for responsive design.
 **/

import React from "react";
import {EffectCoverflow, Pagination, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

const DrvSlider = ({slides = [], slide, sliderClass, slideClass, effect = null, center = false, pagination = false, slidesPerView = 1, breakpoints = {}}) => {
    const Slide = slide;

    return (
        <div className={sliderClass}>
            <Swiper
                effect={effect}
                grabCursor={true}
                centeredSlides={center}
                slidesPerView={slidesPerView}
                loop={true}
                pagination={pagination}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                speed={700}
                breakpoints={breakpoints}
                preventClicks={false}
                loopAdditionalSlides={10}
            >
                {
                    slides.map((item, index) => (
                        <SwiperSlide key={index} className={slideClass}>
                            <Slide item={item} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}

export default DrvSlider;



