import styled from "styled-components";

export const Slide = styled.div`
  width: 990px;
  height: 490px;
  margin: 0px 80px;
  background-color: #ffffff;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 5px 1px;
  border-radius: 10px;

  @media (max-width: 1024px) {
    width: calc(100% - 40px);
    margin: 0px 20px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  
  img {
    width: 80px;
  }
`

export const Content = styled.div`
  color: #99b6c2;
  line-height: 1.7;
  text-align: center;
  font-weight: 400;
  flex: 1;
  overflow: auto;
`

export const Author = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;

  img {
    width: 150px;
    padding: 10px 0px;
  }
`

export const Name = styled.div`
  display: flex;
  color: #394d57;
  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  text-transform: uppercase;
`

export const Position = styled.div`
  display: flex;
  color: #b7c3c9;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  text-align: center;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
`
