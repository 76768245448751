/**
 * DrvStoryComment is Shared Component which is used on DrvSlider as content.
 * Contains comment of any customer on Drivosity.
 *
 * To use this component you need to specify quote named item which includes quote, name and
 * position of customer.
 **/

import React from "react";

import './DrvStoryComment.css';
import {Slide, Header, Content, Author, Name, Position, Footer} from './DrvStoryComment.styles';
import StoriesCommentIcon from '../../../../images/Icons/StoriesComment.svg';
import StoriesStarIcon from '../../../../images/Icons/StoriesStar.svg';
import {DrvButton} from "../../DrvButton/DrvButton";

const DrvStoryComment = ({item}) => {
    return (
        <Slide className='flex flex-col p-5'>
            <Header>
                <img src={StoriesCommentIcon} alt='Comment Icon'/>
            </Header>
            <Content>
                “{item.content}”
            </Content>
            <Author>
                <img src={StoriesStarIcon} alt='5 Star Icon'/>
                <Name>{item.data.name}</Name>
                <Position>{item.data.position}</Position>
            </Author>
            <Footer>
                <DrvButton to="/customer-stories"
                           className="w-fit"
                           background='#ffffff'
                           bordercolor='var(--primary-color)'
                           color='var(--primary-color)'
                           hoverbackground='var(--primary-color)'
                           hovercolor='#ffffff'
                           hoverborder='var(--primary-color)'
                >
                    MORE STORIES
                </DrvButton>
            </Footer>
        </Slide>
    );
}

export default DrvStoryComment;



